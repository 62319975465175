<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="confirm"
    v-bind:style="{ zIndex: options.zIndex}"
  >
      <!-- !![2021.8.4]다른곳과 다르게 보이기 위해 pa-1 를 주었다. 필요없으면 제거할 것 -->
      <v-card class="pa-1">

        <v-toolbar id="movePopArea" dense flat dark class="ma-0 pa-0" color="primary">

          <v-toolbar-items class="__move__pop">
            <v-btn
              dark text class="orange--text text-h6"
              @click.native="confirm"
            >
              확인
            </v-btn>
            <v-btn text icon
              @click="initVals"
            >
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>
          </v-toolbar-items>

          <v-spacer></v-spacer>

          <v-toolbar-title class="white--text">
            <v-icon
              style="font-size: 1rem;font-weight: 550;"
              class="mb-1"
            >mdi-billboard</v-icon>
            회의실정보
          </v-toolbar-title>

          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="warning"
          ></v-progress-linear>
        </v-toolbar>

        <v-card-text class="ma-0 pa-0">
          <v-container fluid class="ma-0 pa-0">

            <v-row no-gutters>
              <v-col cols="12" xs="12" class="mt-0 pb-4">
                <!-- !! list start -->
                <v-card
                  outlined
                  :elevation="0"
                  max-height="500"
                  min-height="500"
                  class="overflow-auto text-body-2 pa-1"
                >
                  <template
                    v-for="item in meetrooms"
                  >
                    <v-hover :key="item.id" v-slot:default="{ hover }">
                      <v-card
                        :elevation="hover ? 8 : 0"
                        class="my-1"
                        min-height="100"
                      >
                        <v-list
                          three-line
                        >
                          <v-list-item>
                            <v-list-item-icon
                              class="mr-4"
                            >
                              <v-avatar
                                tile
                                size="84"
                                :color="hover ? 'grey lighten-2' : 'success lighten-2'"
                              >
                                <v-icon
                                  large
                                  color="teal lighten-1"
                                >mdi-billboard</v-icon>
                              </v-avatar>
                            </v-list-item-icon>
                            <!-- 리스트 내용 -->
                            <v-list-item-content>
                              <v-list-item-title
                                class="primary--text mr-2"
                                :class="hover ? 'font-weight-bold' : ''"
                                style="cursor: pointer;"
                                @click.stop="clickMeetRoom(item)"
                              >
                                {{ item.name }}
                              </v-list-item-title>
                              <v-list-item-subtitle
                                v-show="item.capacity"
                                class="text-body-2 mt-1"
                              >
                                <span class="text-body-2 grey--text ml-1 mr-1">• 수용인원</span>
                                {{ item.capacity }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle
                                v-show="item.equips"
                                class="text-body-2 mt-1"
                              >
                                <span class="text-body-2 grey--text ml-1 mr-1">• 보유장비</span>
                                {{ item.equips }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle
                                v-show="item.remark"
                                class="text-body-2 mt-1"
                              >
                                <span class="text-body-2 grey--text ml-1 mr-1">• 특이사항</span>
                                {{ item.remark }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-hover>
                  </template>
                </v-card>
                <!-- !! list end -->
              </v-col>
              <!-- <v-col cols="12">
                <div class="mb-8"></div>
              </v-col> -->
            </v-row>

          </v-container>
        </v-card-text>

      </v-card>

  </v-dialog>
</template>

<script>
import sleep from '@/lib/sleep'
import cutString from '@/filters/cutString'

export default {
  // 회의실정보
  name: 'MeetRooms',

  components: {
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    options: {
      color: 'primary',
      width: 400,
      height: 500,
      zIndex: 200
    },
    // 구분: 꼼수 - 헤더스에 별로 의미없는 1개만 매칭시킨다.
    headers: [
      {
        text: '',
        value: 'id',
        align: 'start', // !! left 가 아니라 start?
        sortable: false,
        width: '100%'
      }
    ],
    // 구분: 회의실 배열
    meetrooms: [], // * 회의실 데이터 배열
    meetroomList: [], // * 오픈시 부모로 부터 넘겨받은 회의실 목록
    // 구분: 로딩
    loading: false,
    // 구분: v-model 과 params 로 백앤드로 전송되는 객체
    // params: { // 검색용 인자 객체
    //   draw: 0,
    //   where: {},
    //   // * 다른 리스트와 달리 아래 변수는 사용하지 않지만 백앤드에 보내기위해 넣는다.
    //   sort: ['name'], // 주의: 정렬의 갯수만큼 초기값 지정해야 함
    //   order: ['ASC'],
    //   offset: 0,
    //   limit: 1000 // * 기본 리미트 1000
    // },
    // 구분: 검색에 필요한 변수들 - 초기화 필요
    // search: {
    //   sf: 1, // 검색어 검색 select 의 선택된 필드값
    //   sw: '' // 검색어 검색 text box input 값
    // },
    // 구분: 셀렉트 객체들
    // select: {
    //   sf: [ // 검색어 검색 필드 1 셀렉트
    //     { text: '회의실명', value: 1 }
    //   ]
    // },
    // 구분: 기타 변수들
    timeout: null // delay 시 필요
  }),

  watch: {
    'search.sw': { // 검색어 자동 감지
      handler () {
        this.searchWordDelay()
      }
    }
  },

  created () {
  },

  mounted () {
    // this.initialize()
  },

  methods: {
    cutString,
    dummy () {
      console.log('dummy dummy ~ ')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message, color: 'error', timeout: 5000, shaped: true })
    },
    // 중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (const key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    initialize () {
      this.list()
    },
    // 참고: watch 로 검색시 약간의 딜레이를 줘야 한다.
    delay (ms = 800) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.list()
      }, ms)
    },
    // 검색어 검색시 딜레이 주는 함수
    searchWordDelay (ms = 800) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.searchWord()
      }, ms)
    },
    // 중요: 등록시 검색등에 쓰이는 변수 초기화하고 리스트 재패칭
    async initVals () {
      this.doInit().then(() => {
        this.delay(100)
      })
    },
    // 변수 초기화 실행
    doInit () {
      return new Promise((resolve, reject) => {
        // 구분: params 로 백앤드로 전송되는 값
        // this.params.draw = 0
        // this.params.where = {}
        // this.params.sort = ['name'] // *[2022.7.27] 회의실명 가나다순 정렬
        // this.params.order = ['ASC']
        // this.params.offset = 0
        // this.params.limit = 1000 // 기본 리미트 1000개 row 패칭

        // // 구분: 검색용(v-model) 변수 - 초기값 설정이 필요
        // this.search.sf = 1 // 검색어 검색 의 선택된 필드값
        // this.search.sw = '' // 검색어 검색의 text box input 값

        // 구분: 기타 초기값 설정이 필요한 변수들
        this.meetrooms = [] // 회의실 배열

        this.loading = false

        resolve(true)
      })
    },
    // 구분: 열기 함수
    async open (options, meetroomList) {
      this.dialog = true
      this.options = Object.assign(this.options, options)

      this.meetroomList = meetroomList

      // 넘겨받은 회의실 목록
      await this.list()

      // 프로미스를 리턴하면서 resolve를 매칭하고있다.
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    // 구분: 리스트
    async list () {
      try {
        if (this.loading) return
        this.loading = true

        // * 부드러운 로딩을 위해 ... 임의의 시간 딜레이를 두고 실행
        await sleep(500 - Math.floor(Math.random() * 300))

        this.meetrooms = this.meetroomList

        this.loading = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분:[검색] - 검색어 검색 처리 함수
    async searchWord () {
      try {
        // if (this.search.sw.length > 0) { // 검색어가 있으면 파라미터에 넣고
        //   this.params.where.sf = this.search.sf
        //   this.params.where.sw = this.search.sw
        // } else { // 없어도 일단 넣지만 값을 비운다. list() 에서 알아서 삭제된다.
        //   this.params.where.sf = ''
        //   this.params.where.sw = ''
        // }

        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 확인 - 단순 닫기
    async confirm () {
      this.doInit() // 초기화
      this.resolve(0) // reject() 가 아니라 resolve(false)로 던져야 한다.
      this.dialog = false
    },
    // 구분: 회의실 이름 클릭 이벤트 핸들러
    // * [2022.9.16] 회의실을 선택하면 리스트도 변경되게 아이디를 보낸다.
    async clickMeetRoom (meetRoom) {
      try {
        this.doInit() // 초기화
        this.resolve(meetRoom.id)
        this.dialog = false
        //
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/*
  !!참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
/* 2020.11.28 체크박스정렬이 깨져서 주석처리 */
/* .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
} */
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
    text-align: left;
}
/*
  구분: vue2editor css
*/
.vue2editorViewer p {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  /* padding-top: 2px !important; */
  /* padding-bottom: 2px !important; */
}
</style>
